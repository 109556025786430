import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import sha512 from 'js-sha512';
import './index.css';
import PricingCalculatorWizardAllFeatures from './Pricing';
import PricingCalculatorWizard from './PricingBasic';
import PricingPackages from './PricingPackages';
import reportWebVitals from './reportWebVitals';


const STORED_HASH = '20b95822f3d0c2d204ba95fd8d1a4160fc26ef490b142b3662de79cd13c74a0fa68e04f9996a0f5c67a81e4e9171f84111957be3e9741c552e71e1c78a844e71';

// Login component
const Login = ({ onLogin }) => {
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Compare the hash of the entered password with the stored hash
    if (sha512(password) === STORED_HASH) {
      onLogin();
    } else {
      alert('Invalid password');
    }
  };

  return (
    // Simple form with a password input
    // Centered on the page
    <div className="login-container">
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

// App component to manage authentication state
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <React.StrictMode>
      {isAuthenticated ? (
        <BrowserRouter>
          <div>
            <nav className="bg-gray-800 text-white p-4">
              <div className="max-w-7xl mx-auto flex justify-center space-x-8">
                <a href="/" className="hover:text-gray-300">Packages Overview</a>
                <a href="/calculator" className="hover:text-gray-300">Full Calculator</a>
                <a href="/approvals" className="hover:text-gray-300">Approvals Calculator</a>
              </div>
            </nav>
            <Routes>
              <Route path="/calculator" element={<PricingCalculatorWizardAllFeatures />} />
              <Route path="/approvals" element={<PricingCalculatorWizard />} />
              <Route path="/" element={<PricingPackages />} />
            </Routes>
          </div>
        </BrowserRouter>
      ) : (
        <Login onLogin={handleLogin} />
      )}

    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();