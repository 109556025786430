import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent } from "./components/ui/card";
import { Button } from "./components/ui/button";
import { Input } from "./components/ui/input";
import { Slider } from "./components/ui/slider";
import { Checkbox } from "./components/ui/checkbox";

const PricingCalculatorWizardAllFeatures = () => {
  const [step, setStep] = useState(0);
  const [airTransactions, setAirTransactions] = useState(0);
  const [hotelTransactions, setHotelTransactions] = useState(0);
  const [estimatedPNRs, setEstimatedPNRs] = useState(0);
  const [tier, setTier] = useState('');
  const [selectedFeatures, setSelectedFeatures] = useState({});

  const [openAccordions, setOpenAccordions] = useState({});

  const features = [
    {
      name: 'FCM Approvals',
      description: 'The FCM approvals tool includes the ability to',
      bulletPoints: [
        'Active approval flow',
        'Passive approval flow',
        'Site redirect',
        'custom notifications'
      ],
      prices: [0.50, 0.35, 0.25]
    },
    {
      name: 'Authorisations',
      description: 'Pre-booking travel authorisation system',
      bulletPoints: [
        'Pre-booking travel authorisation',
        'Customizable approval workflows',
        'Integration with booking tools'
      ],
      prices: [0.50, 0.35, 0.25]
    },
    {
      name: 'Communications',
      description: 'Cross-site communications powered by the FCM Ext',
      bulletPoints: [
        'Dynamic integrations:',
        'Sherpa / Crisis 24 (old safety & risk module)',
        'Magnatech (unused credits AMER)',
        'What if Analysis & Travel manager tools',
      ],
      prices: [0.50, 0.40, 0.35]
    },
    {
      name: 'Advanced Reporting',
      description: 'Enhanced reporting in PowerBI',
      bulletPoints: [
        'AI Reporting',
        'Flight Status'
      ],
      prices: [0.55, 0.45, 0.35]
    },
    {
      name: 'Data Hand Off',
      description: 'Data integration and transfer services',
      bulletPoints: [
        'Automated data transfer',
        'Custom data formats',
        'Real-time synchronization'
      ],
      prices: [0.20, 0.18, 0.15]
    },
    {
      name: 'Sustainability',
      description: 'Enhanced sustainability powered by Thrust Carbon',
      bulletPoints: [
        'Thrust Carbon Emissions info in Itinerary',
        'Thrust carbon dynamic notifications in FCMX',
        'Thrust carbon emissions in Reporting'
      ],
      prices: [0.20, 0.18, 0.15]
    }
  ];

  useEffect(() => {
    const totalPNRs = airTransactions + (hotelTransactions * 0.6);
    setEstimatedPNRs(Math.round(totalPNRs));
    setTier(calculateTier(totalPNRs));
  }, [airTransactions, hotelTransactions]);

  const calculateTier = (pnrs) => {
    if (pnrs < 10000) return 'Small';
    if (pnrs < 50000) return 'Medium';
    return 'Large';
  };

  const getPrice = (feature) => {
    const tierIndex = tier === 'Small' ? 0 : tier === 'Medium' ? 1 : 2;
    return feature.prices[tierIndex];
  };

  const calculateTotal = () => {
    // First calculate base totals for each feature
    const featureTotals = {};
    Object.entries(selectedFeatures).forEach(([name, selected]) => {
      if (selected && !features.find(f => f.name === name).comingSoon) {
        const feature = features.find(f => f.name === name);
        featureTotals[name] = getPrice(feature) * (airTransactions + hotelTransactions);
      }
    });

    // Apply Approvals + Authorizations bundle discount if both are selected
    const hasApprovals = selectedFeatures['FCM Approvals'];
    const hasAuthorizations = selectedFeatures['Authorisations'];
    let bundleDiscount = 0;
    
    if (hasApprovals && hasAuthorizations) {
      // Apply 20% discount to both services
      const approvalsDiscount = featureTotals['FCM Approvals'] * 0.2;
      const authorizationsDiscount = featureTotals['Authorisations'] * 0.2;
      bundleDiscount = approvalsDiscount + authorizationsDiscount;
    }

    // Calculate base total after bundle discount
    const baseTotal = Object.values(featureTotals).reduce((total, amount) => total + amount, 0) - bundleDiscount;

    // Calculate multi-package discount (excluding the bundle discount)
    const selectedCount = Object.values(selectedFeatures).filter(Boolean).length;
    const discountPercentage = Math.max(0, (selectedCount - 1) * 0.05); // 5% for each additional package

    return {
      baseTotal: Object.values(featureTotals).reduce((total, amount) => total + amount, 0),
      bundleDiscount,
      discountPercentage,
      discountedTotal: baseTotal * (1 - discountPercentage),
      selectedCount,
      hasApprovalBundle: hasApprovals && hasAuthorizations
    };
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-8">Enter your annual transaction volumes:</h2>
            <div className="mb-8">
              <label className="block mb-4 text-lg font-semibold">Air Transactions:</label>
              <Slider
                className="mb-4"
                min={100}
                max={100000}
                step={100}
                value={[airTransactions]}
                onValueChange={(value) => setAirTransactions(value[0])}
                style={{ "--slider-track-fill-color": "#4CAF50" }}
              />
              <Input
                type="number"
                value={airTransactions}
                onChange={(e) => setAirTransactions(Number(e.target.value))}
              />
            </div>
            <div className="mb-8">
              <label className="block mb-4 text-lg font-semibold">Hotel Transactions:</label>
              <Slider
                className="mb-4"
                min={100}
                max={100000}
                step={100}
                value={[hotelTransactions]}
                onValueChange={(value) => setHotelTransactions(value[0])}
                style={{ "--slider-track-fill-color": "#4CAF50" }}
              />
              <Input
                type="number"
                value={hotelTransactions}
                onChange={(e) => setHotelTransactions(Number(e.target.value))}
              />
            </div>
            <div className="bg-gray-100 p-4 rounded-lg mb-6">
              <p className="font-semibold">Estimated PNRs: {estimatedPNRs}</p>
              <p className="font-semibold">Your Category: {tier}</p>
              <p className="text-sm mt-2">Tier Thresholds:</p>
              <ul className="text-sm list-disc list-inside">
                <li>Small: 0 - 9,999 PNRs</li>
                <li>Medium: 10,000 - 49,999 PNRs</li>
                <li>Large: 50,000+ PNRs</li>
              </ul>
            </div>
            <Button onClick={() => setStep(1)}>Next</Button>
          </div>
        );
      case 1:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Select your features</h2>
            <div className="bg-blue-50 p-4 rounded-lg mb-6">
              <h3 className="font-semibold mb-2">Multi-Package Discount!</h3>
              <p className="text-sm mb-2">Get a progressive discount when you select multiple packages:</p>
              <ul className="text-sm list-disc list-inside mb-2">
                <li>First package: Full price</li>
                <li>Two packages: 5% off total</li>
                <li>Three packages: 10% off total</li>
                <li>Four packages: 15% off total</li>
                <li>And so on...</li>
              </ul>
              <p className="text-sm text-blue-600 font-semibold">The more you choose, the more you save!</p>
            </div>
            <div className="bg-yellow-50 p-4 rounded-lg mb-6">
              <h3 className="font-semibold mb-2">Special Bundle Offer! 🌟</h3>
              <p className="text-sm">Get 20% off both FCM Approvals and Authorisations when you select them together!</p>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg mb-6">
              <p className="font-semibold">Estimated PNRs: {estimatedPNRs}</p>
              <p className="font-semibold">Your Category: {tier}</p>
              <p className="text-sm mt-2">Tier Thresholds:</p>
              <ul className="text-sm list-disc list-inside">
                <li>Small: 0 - 9,999 PNRs</li>
                <li>Medium: 10,000 - 49,999 PNRs</li>
                <li>Large: 50,000+ PNRs</li>
              </ul>
            </div>
            {features.map((feature) => (
              <div key={feature.name} className="mb-6 border rounded-lg p-4">
                <div className="flex items-start justify-between mb-2">
                  <div className="flex items-start space-x-3">
                    <Checkbox
                      id={feature.name}
                      checked={selectedFeatures[feature.name] || false}
                      onCheckedChange={(checked) =>
                        setSelectedFeatures(prev => ({ ...prev, [feature.name]: checked }))
                      }
                    />
                    <div>
                      <label htmlFor={feature.name} className="font-semibold text-lg">
                        {feature.name}
                      </label>
                      <p className="text-gray-600 mt-1">{feature.description}</p>
                    </div>
                  </div>
                  <div className="text-right text-sm">
                    <div className="font-semibold">Price per PNR:</div>
                    <div className="text-lg font-bold text-blue-600">
                      ${tier === 'Small' ? feature.prices[0] : 
                        tier === 'Medium' ? feature.prices[1] : 
                        feature.prices[2]}
                    </div>
                    <div className="text-gray-500 text-xs">
                      {tier} tier pricing
                    </div>
                  </div>
                </div>
                <div className="ml-8">
                  <button
                    onClick={() => setOpenAccordions(prev => ({
                      ...prev,
                      [feature.name]: !prev[feature.name]
                    }))}
                    className="text-blue-600 hover:text-blue-800 text-sm flex items-center mt-2"
                  >
                    {openAccordions[feature.name] ? 'Hide Details' : 'Show Details'}
                    <svg
                      className={`ml-1 h-4 w-4 transform transition-transform ${
                        openAccordions[feature.name] ? 'rotate-180' : ''
                      }`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  {openAccordions[feature.name] && (
                    <div className="mt-2 pl-4">
                      <ul className="list-disc space-y-1 text-sm text-gray-600">
                        {feature.bulletPoints.map((point, index) => (
                          <li key={index}>{point}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            ))}
            <Button onClick={() => setStep(2)}>Calculate Total</Button>
          </div>
        );
      case 2:
        const { baseTotal, bundleDiscount, discountPercentage, discountedTotal, selectedCount, hasApprovalBundle } = calculateTotal();
        const quarterlyTotal = discountedTotal / 4;
        const annualDiscountedTotal = discountedTotal * 0.8; // Additional 20% off for annual payment
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Your Pricing Summary</h2>
            <div className="bg-blue-50 p-4 rounded-lg mb-6">
              <h3 className="font-semibold mb-2">Multi-Package Savings</h3>
              <p className="text-sm">You've selected {selectedCount} package{selectedCount !== 1 ? 's' : ''}, 
              {discountPercentage > 0 
                ? ` qualifying for a ${(discountPercentage * 100)}% discount!` 
                : ' select more packages to unlock discounts!'}</p>
            </div>
            {hasApprovalBundle && (
              <div className="bg-yellow-50 p-4 rounded-lg mb-6">
                <h3 className="font-semibold mb-2">Bundle Savings Applied!</h3>
                <p className="text-sm">You're saving 20% on both FCM Approvals and Authorisations with our bundle offer!</p>
              </div>
            )}
            <div className="bg-green-50 p-4 rounded-lg mb-6">
              <h3 className="font-semibold mb-2">Save More with Annual Payment! 💰</h3>
              <p className="text-sm">Get an additional 20% discount when you pay annually instead of quarterly!</p>
            </div>
            <p className="text-sm text-gray-700 mb-4 font-semibold">
              Choose between quarterly payments or save more with annual payment.
            </p>
            <table className="w-full mb-4 border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border p-2 text-left">Feature</th>
                  <th className="border p-2 text-right">Price per Transaction</th>
                  <th className="border p-2 text-right">Annual Cost</th>
                </tr>
              </thead>
              <tbody>
                {features.map((feature) => (
                  selectedFeatures[feature.name] && (
                    <tr key={feature.name} className={feature.comingSoon ? "text-gray-400" : ""}>
                      <td className="border p-2">{feature.name} {feature.comingSoon && '(Coming Soon)'}</td>
                      <td className="border p-2 text-right">${getPrice(feature).toFixed(2)}</td>
                      <td className="border p-2 text-right">
                        ${(getPrice(feature) * (airTransactions + hotelTransactions)).toFixed(2)}
                      </td>
                    </tr>
                  )
                ))}
                <tr className="font-bold">
                  <td className="border p-2">Initial Total</td>
                  <td className="border p-2"></td>
                  <td className="border p-2 text-right">${baseTotal.toFixed(2)}</td>
                </tr>
                {bundleDiscount > 0 && (
                  <tr className="text-yellow-600 font-semibold">
                    <td className="border p-2">Approvals Bundle Discount (20%)</td>
                    <td className="border p-2"></td>
                    <td className="border p-2 text-right">-${bundleDiscount.toFixed(2)}</td>
                  </tr>
                )}
                {discountPercentage > 0 && (
                  <tr className="text-green-600">
                    <td className="border p-2">Multi-Package Discount ({(discountPercentage * 100)}%)</td>
                    <td className="border p-2"></td>
                    <td className="border p-2 text-right">-${((baseTotal - bundleDiscount) * discountPercentage).toFixed(2)}</td>
                  </tr>
                )}
                <tr className="font-bold text-lg border-t-2">
                  <td className="border p-2">Subtotal after Package Discounts</td>
                  <td className="border p-2"></td>
                  <td className="border p-2 text-right">${discountedTotal.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div className="bg-white p-6 rounded-lg border-2 border-gray-200">
                <h3 className="text-xl font-bold mb-2">Quarterly Payment Plan</h3>
                <p className="text-3xl font-bold text-blue-600 mb-2">${quarterlyTotal.toFixed(2)}</p>
                <p className="text-sm text-gray-600">per quarter</p>
                <p className="text-sm text-gray-600 mt-2">Annual total: ${discountedTotal.toFixed(2)}</p>
              </div>
              <div className="bg-green-50 p-6 rounded-lg border-2 border-green-400">
                <div className="flex items-start justify-between mb-2">
                  <h3 className="text-xl font-bold">Annual Payment</h3>
                  <span className="bg-green-500 text-white text-sm px-2 py-1 rounded">Save 20%</span>
                </div>
                <p className="text-3xl font-bold text-green-600 mb-2">${annualDiscountedTotal.toFixed(2)}</p>
                <p className="text-sm text-gray-600">one-time payment</p>
                <p className="text-sm text-green-600 mt-2">You save: ${(discountedTotal - annualDiscountedTotal).toFixed(2)}</p>
              </div>
            </div>
            <Button onClick={() => setStep(0)}>Start Over</Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Card className="max-w-4xl mx-auto">
      <CardHeader>
        <h1 className="text-3xl font-bold">Travel Tech Pricing Calculator Wizard</h1>
      </CardHeader>
      <CardContent>{renderStep()}</CardContent>
    </Card>
  );
};

export default PricingCalculatorWizardAllFeatures;