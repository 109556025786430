import React, { useState } from 'react';
import { Card, CardHeader, CardContent } from "./components/ui/card";

const PricingPackages = () => {
  const [openAccordions, setOpenAccordions] = useState({});

  const packages = [
    {
      name: 'FCM Approvals',
      description: 'The FCM approvals tool includes the ability to',
      bulletPoints: [
        'Active approval flow',
        'Passive approval flow',
        'Site redirect',
        'custom notifications'
      ],
      pricing: {
        small: '$0.50',
        medium: '$0.35',
        large: '$0.25'
      }
    },
    {
      name: 'Authorisations',
      description: 'Pre-booking travel authorisation system',
      bulletPoints: [
        'Pre-booking travel authorisation',
        'Customizable approval workflows',
        'Integration with booking tools'
      ],
      pricing: {
        small: '$0.50',
        medium: '$0.35',
        large: '$0.25'
      }
    },
    {
      name: 'Communications',
      description: 'Cross-site communications powered by the FCM Ext',
      bulletPoints: [
        'Dynamic integrations:',
        'Sherpa / Crisis 24 (old safety & risk module)',
        'Magnatech (unused credits AMER)',
        'What if Analysis & Travel manager tools',
      ],
      pricing: {
        small: '$0.50',
        medium: '$0.40',
        large: '$0.35'
      }
    },
    {
      name: 'Advanced Reporting',
      description: 'Enhanced reporting in PowerBI',
      bulletPoints: [
        'AI Reporting',
        'Flight Status'
      ],
      pricing: {
        small: '$0.55',
        medium: '$0.45',
        large: '$0.35'
      }
    },
    {
      name: 'Data Hand Off',
      description: 'Data integration and transfer services',
      bulletPoints: [
        'Automated data transfer',
        'Custom data formats',
        'Real-time synchronization'
      ],
      pricing: {
        small: '$0.20',
        medium: '$0.18',
        large: '$0.15'
      }
    },
    {
      name: 'Sustainability',
      description: 'Enhanced sustainability powered by Thrust Carbon',
      bulletPoints: [
        'Thrust Carbon Emissions info in Itinerary',
        'Thrust carbon dynamic notifications in FCMX',
        'Thrust carbon emissions in Reporting'
      ],
      pricing: {
        small: '$0.20',
        medium: '$0.18',
        large: '$0.15'
      }
    }
  ];

  const toggleAccordion = (packageName) => {
    setOpenAccordions(prev => ({
      ...prev,
      [packageName]: !prev[packageName]
    }));
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4 text-center">Pricing Packages</h1>
      
      <div className="bg-blue-50 p-6 rounded-lg mb-8">
        <h2 className="text-xl font-bold mb-3">Save More with Multi-Package Discounts!</h2>
        <p className="mb-3">Take advantage of our progressive discount structure when you combine multiple packages:</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="bg-white p-4 rounded-lg shadow">
            <div className="text-lg font-bold mb-2">1 Package</div>
            <div className="text-gray-600">Full Price</div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <div className="text-lg font-bold mb-2">2 Packages</div>
            <div className="text-green-600">5% Off Total</div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <div className="text-lg font-bold mb-2">3 Packages</div>
            <div className="text-green-600">10% Off Total</div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <div className="text-lg font-bold mb-2">4+ Packages</div>
            <div className="text-green-600">15%+ Off Total</div>
          </div>
        </div>
        <p className="mt-3 text-sm text-blue-600">Additional 5% discount for each package beyond the first. Mix and match any packages to maximize your savings!</p>
      </div>

      <div className="grid gap-6">
        {packages.map((pkg) => (
          <Card key={pkg.name} className="overflow-hidden">
            <div className="p-6">
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h2 className="text-2xl font-bold mb-2">{pkg.name}</h2>
                  <p className="text-gray-600">{pkg.description}</p>
                </div>
                <div className="text-right">
                  <div className="mb-1">
                    <span className="font-semibold">Small (0-9,999 PNRs):</span> {pkg.pricing.small}
                  </div>
                  <div className="mb-1">
                    <span className="font-semibold">Medium (10,000-49,999 PNRs):</span> {pkg.pricing.medium}
                  </div>
                  <div>
                    <span className="font-semibold">Large (50,000+ PNRs):</span> {pkg.pricing.large}
                  </div>
                </div>
              </div>
              <button
                onClick={() => toggleAccordion(pkg.name)}
                className="text-blue-600 hover:text-blue-800 font-semibold flex items-center"
              >
                {openAccordions[pkg.name] ? 'Hide Details' : 'Show Details'}
                <svg
                  className={`ml-2 h-5 w-5 transform transition-transform ${
                    openAccordions[pkg.name] ? 'rotate-180' : ''
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {openAccordions[pkg.name] && (
                <div className="mt-4 pl-4">
                  <ul className="list-disc space-y-2">
                    {pkg.bulletPoints.map((point, index) => (
                      <li key={index} className="text-gray-700">{point}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default PricingPackages;